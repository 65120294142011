<template>
  <div class="p-6 apply">
    <div class="text-2xl font-bold mdmax:text-2xl mb-2">Apply for this job</div>
    <div class="mb-6 px-0.5 text-base font-semibold">
      {{ vacancyDetail.position }}
    </div>
    <div class="mb-6 px-0.5">
      <TextField borderEnabled v-model="payloads.name" label="Name" placeholder="Enter your name" />
    </div>
    <div class="mb-6 px-0.5">
      <TextField borderEnabled v-model="payloads.email" label="Email" placeholder="Enter your email address" />
    </div>
    <div class="mb-6 px-0.5">
      <TextField borderEnabled v-model="payloads.currentCity" label="Current City" placeholder="Example: Jakarta Selatan" />
    </div>
    <div class="mb-6 px-0.5">
      <TextField type="number" borderEnabled v-model="payloads.phoneNumber" label="Phone Number" placeholder="Enter your phone number" />
    </div>
    <div class="mb-6 px-0.5 relative">
      <Upload @select="setFile" />
    </div>
    <p v-if="error" class="text-system-error text-xs mb-6">{{ error }}</p>
    <div class="mb-2">
      <Button buttonText="Send Application" :disabled="isDisabled" type="primary" size="regular" @action="submitApplication()" />
    </div>
  </div>
</template>
<script>
import { showVueToast } from '@/utils'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "DropDown" */ '@/components/forms/TextField'),
    Upload: () => import(/* webpackChunkName: "Upload" */ '@/views/Career/Upload/Upload')
  },
  props: {
    vacancyId: String
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  data() {
    return {
      payloads: {
        name: null,
        email: null,
        currentCity: null,
        phoneNumber: null
      },
      seletedFile: {},
      error: null,
      isReadyToSend: false,
      isLoading: false,
      selectedFile: null,
      apiImg: {
        uploadUrl: '',
        key: ''
      },
      typeImg: '',
      fileName: '',
      response: { image: '' }
    }
  },
  computed: {
    ...mapGetters('career', ['vacancyDetail']),
    isDisabled() {
      // return false
      return !this.payloads.name || !this.payloads.email || !this.payloads.currentCity || !this.payloads.phoneNumber || !this.selectedFile
    }
  },
  methods: {
    ...mapActions('career', ['applyJob']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['getFormToken']),
    async upladImageControl(url) {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      const { data } = await axios({
        method: 'GET',
        url: `${baseUrl}/uploads?contentType=${this.typeImg}`
      })
      this.apiImg = data
      const binary = atob(url.split(',')[1])
      const array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      const blobData = new Blob([new Uint8Array(array)], { type: this.typeImg })
      await fetch(this.apiImg.uploadUrl, {
        method: 'PUT',
        body: blobData
      })
    },
    setFile(item) {
      this.selectedFile = item?.[0]
      this.createImage(this.selectedFile)
    },
    createImage(file) {
      this.typeImg = file.type
      this.fileName = file.name
      const reader = new FileReader()
      reader.onload = (e) => {
        this.response.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async submitApplication() {
      // eslint-disable-next-line
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (!regex.test(this.payloads.email)) {
        this.error = 'Email format is invalid!'
      } else {
        this.error = null
        let formToken = null
        this.showLoading()
        await this.upladImageControl(this.response.image)
        await this.getFormToken().then((response) => {
          formToken = response.data.data
        })
        const requestBody = {
          currentCity: this.payloads.currentCity,
          email: this.payloads.email,
          name: this.payloads.name,
          phoneNumber: this.payloads.phoneNumber,
          temporaryCvUrl: `${this.apiImg.uploadUrl}/${this.apiImg.key}`
        }
        this.applyJob({
          vacancyId: this.vacancyId,
          payloads: requestBody,
          formToken: formToken
        }).then((res) => {
          this.hideLoading()
          this.$emit('closeModal')
          showVueToast("You've successfully submited your application!", 'success', 2000)
        })
      }
    }
  }
}
</script>
